import React, { useContext } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AppContext from '../../../@crema/utility/AppContext';
import { ThemeMode } from '../../constants/AppEnums';
import Hidden from '@material-ui/core/Hidden';
import AppContextPropsType from '../../../types/AppContextPropsType';

const AppStoreLogos = () => {
    const { themeMode } = useContext<AppContextPropsType>(AppContext);
    const useStyles = makeStyles(() => ({
        logoRoot: {
            display: 'flex',
            flexDirection: 'row',
            cursor: 'pointer',
            alignItems: 'center',
        },
        logo: {
            height: 44,
            marginRight: 10,
        },
        qrCode: {
            height: 80,
            marginRight: 10,
        },
    }));
    const classes = useStyles();
    return (
        <>
        <Box className={classes.logoRoot}>
            <Hidden smUp>
                    <img
                        className={classes.qrCode}
                    src={
                        themeMode === ThemeMode.DARK
                            ? '/assets/images/appstoreimages/AppStoreQRCode.png'
                            : '/assets/images/appstoreimages/AppStoreQRCode.png'
                    }
                        alt='EdgeAppStoreQRCode'

                />
            </Hidden>
            <Hidden xsDown>
                <img
                    className={classes.qrCode}
                    src={
                        themeMode === ThemeMode.DARK
                            ? '/assets/images/appstoreimages/AppStoreQRCode.png'
                            : '/assets/images/appstoreimages/AppStoreQRCode.png'
                    }
                        alt='EdgeAppStoreQRCode'
                />
                </Hidden>
                <Hidden smUp>
                    <img
                        className={classes.logo}
                        src={
                            themeMode === ThemeMode.DARK
                                ? '/assets/images/appstoreimages/DownloadItOnTheAppStore.png'
                                : '/assets/images/appstoreimages/DownloadItOnTheAppStore.png'
                        }
                        alt='DownloadEdgeForIos'
                        onClick={() => window.open("https://apps.apple.com/us/app/i-b-c-edge/id6444536227", '_blank')}
                    />
                </Hidden>
                <Hidden xsDown>
                    <img
                        className={classes.logo}
                        src={
                            themeMode === ThemeMode.DARK
                                ? '/assets/images/appstoreimages/DownloadItOnTheAppStore.png'
                                : '/assets/images/appstoreimages/DownloadItOnTheAppStore.png'
                        }
                        alt='DownloadEdgeForIos'
                        onClick={() => window.open("https://apps.apple.com/us/app/i-b-c-edge/id6444536227", '_blank')}
                    />
                </Hidden>
        </Box>
        </>
    );
};

export default AppStoreLogos;
