import React from 'react';

export const incentivesPagesConfig = [
    {
        auth: ['user'],
        routes: [
            {
                path: '/incentives',
                component: React.lazy(() => import('./Pages/IncentivesPage')),
            }
        ]
    }
];
