import React from 'react';

export const merchandisePagesConfig = [
    {
        auth: ['user'],
        routes: [
            {
                path: '/merchandise/coolers',
                component: React.lazy(() => import('./Pages/MerchandiseCoolersPage')),
            },
            {
                path: '/merchandise/availableCoolers',
                component: React.lazy(() => import('./Pages/MerchandiseAvailableCoolersPage')),
            },
            {
                path: '/merchandise/planograms',
                component: React.lazy(() => import('./Pages/MerchandisePlanogramsPage')),
            },
                        {
                path: '/merchandise/syndigo',
                component: React.lazy(() => import('./Pages/MerchandiseSyndigoPage')),
            }
        ]
    }
];
