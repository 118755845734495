import React from 'react';

export const pointOfSalePagesConfig = [
    {
        auth: ['user'],
        routes: [
            {
                path: '/pos/my-pos',
                component: React.lazy(() => import('./Pages/PointOfSaleMyPosPage')),
            },
            {
                path: '/pos/order-custom-pos',
                component: React.lazy(() => import('./Pages/PointOfSaleOrderCustomPosPage')),
            }
        ]
    }
];
