export interface NavItemProps {
  id: string;
  messageId: string;
  title: string;
  icon?: string;
  customIcon?: string;
  exact?: boolean;
  url?: string;
  type?: string;
  count?: number;
  color?: string;
  auth?: string[];
  children?: NavItemProps[] | NavItemProps;
}

const routesConfig: NavItemProps[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    messageId: 'sidebar.dashboard',
    icon: 'dashboard',
    type: 'item',
    url: '/dashboard'
  },
  {
    id: 'details',
    title: 'Details',
    messageId: 'sidebar.details',
    icon: 'portrait',
    type: 'item',
    url: '/account-details'
  },
  {
    id: 'newsletter',
    title: 'Newsletter',
    messageId: 'sidebar.newsletter',
    icon: 'menubook',
    type: 'item',
    url: '/newsletter'
    },
    {
        id: 'incentives',
        title: 'Incentives',
        messageId: 'sidebar.incentives',
        icon: 'savings',
        type: 'item',
        url: '/incentives'
    },
  {
    id: 'rebates',
    title: 'Rebates',
    messageId: 'sidebar.rebates',
    type: 'collapse',
    icon: 'money',
    children: [
      {
        id: 'rebates',
        title: 'rebates',
        messageId: 'sidebar.rebates.invoice',
        type: 'item',
        url: '/rebate/invoice'
      },
      {
        id: 'fifthCheck',
        title: 'Fifth Check',
        messageId: 'sidebar.rebates.fifthCheck',
        type: 'item',
        url: '/rebate/fifth-check'
      }
    ],
  },
  {
    id: 'merchandise',
    title: 'Merchandise',
    messageId: 'sidebar.merchandise',
    type: 'collapse',
    icon: 'margin',
    children: [
      {
        id: 'coolers',
        title: 'coolers',
        messageId: 'sidebar.merchandise.coolers',
        type: 'item',
        url: '/merchandise/coolers'
      },
      {
        id: 'planograms',
        title: 'planograms',
        messageId: 'sidebar.merchandise.planograms',
        type: 'item',
        url: '/merchandise/planograms'
      },
    ],
  },
  {
    id: 'pointOfSale',
    title: 'PointOfSale',
    messageId: 'sidebar.pos',
    type: 'collapse',
    icon: 'photolibrary',
    children: [
      {
        id: 'myPos',
        title: 'myPos',
        messageId: 'sidebar.pos.myPos',
        type: 'item',
        url: '/pos/my-pos'
      },
      {
        id: 'orderCustomPos',
        title: 'orderCustomPos',
        messageId: 'sidebar.pos.orderCustomPos',
        type: 'item',
        url: '/pos/order-custom-pos'
      }
    ],
  },
  {
    id: 'programs',
    title: 'Programs',
    messageId: 'sidebar.programs',
    type: 'collapse',
    icon: 'storage',
    children: [
      {
        id: 'active',
        title: 'active',
        messageId: 'sidebar.programs.active',
        type: 'item',
        url: '/programs/active'
      },
      {
        id: 'available',
        title: 'available',
        messageId: 'sidebar.programs.available',
        type: 'item',
        url: '/programs/available'
      }
    ],
  },
  {
    id: 'services',
    title: 'Services',
    messageId: 'sidebar.services',
    type: 'collapse',
    icon: 'build',
    children: [
      {
        id: 'feloniousInsurance',
        title: 'feloniousInsurance',
        messageId: 'sidebar.services.feloniousInsurance',
        type: 'item',
        url: '/services/felonious-insurance'
      }
    ],
  },
  {
    id: 'easystoreApps',
    title: 'EasyStoreApps',
    messageId: 'sidebar.easystoreApps',
    type: 'collapse',
    icon: 'apps',
    children: [
      {
        id: 'easystorePro',
        title: 'easystorePro',
        messageId: 'sidebar.easystoreApps.easystorePro',
        type: 'item',
        url: '/easystore-apps/easystore-pro'
      },
    ],
  },
  {
    id: 'chaintools',
    title: 'Chain Tools',
    messageId: 'sidebar.chaintools',
    icon: 'widgets',
    type: 'item',
    url: '/chaintools'
  }
];
export default routesConfig;
