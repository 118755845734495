import React from 'react';

export const easystoreAppsPagesConfig = [
    {
        auth: ['user'],
        routes: [
            {
                path: '/easystore-apps/easystore-pro',
                component: React.lazy(() => import('./Pages/EasystoreProPage')),
            },
            {
                path: '/easystore-apps/easystore-dash',
                component: React.lazy(() => import('./Pages/EasystoreDashPage')),
            },
            {
                path: '/easystore-apps/easystore-eats',
                component: React.lazy(() => import('./Pages/EasystoreEatsPage')),
            }
        ]
    }
];
