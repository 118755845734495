import React, { useState, createContext } from 'react';

export const UserContext = createContext();

export const UserProvider = (props) => {
    const [userStores, setUserStores] = useState([]);
    const [currentStore, setCurrentStore] = useState([]);

    return (
        <UserContext.Provider
            value={{ userStores, setUserStores, currentStore, setCurrentStore }}
        >
            {props.children}
        </UserContext.Provider>

    )
}