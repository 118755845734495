/*import authentication from '@kdpw/msal-b2c-react'*/
import axios from 'axios'

const unwrapResponse = (responseObj) => {
    return responseObj;
}

const instance = axios.create({
    baseURL: process.env.REACT_APP_MEMBER_PORTAL_API_BASE_URL + 'api',
    headers: {
        'content-type': 'application/json'
    }
});

const salesforceApi = {

    getMyActivePrograms: (ibcNumber) =>
        instance({
            'method': 'GET',
            'url': '/myactiveprograms/' + ibcNumber,
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                if (!responseObj) return;
                return responseObj;
            }]
        }),
    getMyDashboardApi: (ibcNumber) =>
        instance({
            'method': 'GET',
            'url': '/myDashboardApi?ibcNumber=' + ibcNumber + '&requestSource=Web',
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                if (!responseObj) return;
                return responseObj;
            }]
        }),
    getMyAvailablePrograms: (ibcNumber) =>
        instance({
            'method': 'GET',
            'url': '/myavailableprograms/' + ibcNumber,
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                if (!responseObj) return;
                return responseObj;
            }]
        }),
    postMyAvailablePrograms: (request) =>
        instance({
            'method': 'POST',
            'url': `/myavailableprograms/?ibcNumber=${request.ibcNumber}&program=${request.id}&advertising=${request.hasProgram}&distributor=${request.distributor}&accountNumber=${request.accountNumber}`,
            'headers': {
                'content-type': 'text/plain'
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                if (!responseObj) return;
                return responseObj;
            }]
        }),
    getMyNewsletterApi: (ibcNumber) =>
        instance({
            'method': 'GET',
            'url': '/mynewsletterapi/' + ibcNumber,
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                if (!responseObj) return;
                return responseObj;
            }]
        }),
    getMyRegionalManagerApi: (ibcNumber) =>
        instance({
            'method': 'GET',
            'url': '/myregionalmanagerapi/' + ibcNumber,
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                if (!responseObj) return;
                return responseObj;
            }]
        }),
    getUserStoreCount: (email) =>
      instance({
        'method': 'GET',
        'url': '/chaintoolsapi/GetNumberOfStoresInUserProfile/' + email,
        transformResponse: [function(data) {
          console.log('api response:' +  unwrapResponse(JSON.parse(data)));
          const responseObj = unwrapResponse(JSON.parse(data));
          if (!responseObj) return;
          return responseObj;
        }]
      }),
    getMyStoreApi: (ibcNumber) =>
        instance({
            'method': 'GET',
            'url': '/mystoreapi/' + ibcNumber,
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                if (!responseObj) return;
                return responseObj;
            }]
        }),
    getMyRebateReceipt: (ibcNumber,email) =>
        instance({
            'method': 'GET',
            'url': '/myrebatereceipt?ibcNumber=' + ibcNumber + '&emailAddress='+email,
            transformResponse: [function (data) {
                const responseObj = JSON.parse(data);
                if (!responseObj) return;
                return responseObj;
            }]
        }),
        getMyFifthCheckApi: (ibcNumber,email) =>
          instance({
            'method': 'GET',
            'url': '/myfifthcheckapi?ibcNumber=' + ibcNumber + '&emailAddress='+email,
            transformResponse: [function (data) {
              const responseObj = JSON.parse(data);
              if(!responseObj) return;
              return responseObj;
            }]
          }),
    getIbcStoreChain: (adDisplayName) =>
          instance({
            'method': 'GET',
            'url': '/ibcstorechain/' + adDisplayName,
            transformResponse: [function (data) {
              const responseObj = JSON.parse(data);
              if(!responseObj) return;
              return responseObj;
            }]
          }),
        portalNewsletterPayment: (ibcNumber) =>
        instance({
            'method': 'GET',
            'url': '/portalnewsletterpaymentapi/' + ibcNumber,
            transformResponse: [function (data) {
                const responseObj = JSON.parse(data);
                if (!responseObj) return;
                return responseObj;
            }]
        }),
        getMyPosAdvertising: (ibcNumber) =>
            instance({
                'method': 'GET',
                'url': '/myposadvertising/' + ibcNumber,
                transformResponse: [function (data) {
                    const responseObj = JSON.parse(data);
                    if (!responseObj) return;
                    return responseObj;
                }]
            }),
        getMyPlanogramApi: (ibcNumber) => instance({
            'method': 'GET',
            'url': '/myplanogram/' + ibcNumber,
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                if (!responseObj) return;
                return responseObj;
            }]
        }),
        getIncentives: (ibcNumber) => instance({
            'method': 'GET',
            'url': '/incentivesblobservice/GetIncentives/' + ibcNumber,
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                if (!responseObj) return;
                return responseObj;
            }]
        }),
        postIncentiveBlob: (request) => instance({
            'method': 'POST',
            'url': `/incentivesblobservice/PostIncentiveBlob?FirstName=${request.firstName}&LastName=${request.lastName}&Email=${request.email}&CellNumber=${request.cellNumber}&IbcNumber=${request.ibcNumber}&IncentiveId=${request.incentiveId}&Source=${request.source}`,
            data: request.formData,
            'headers': {
                'Content-Type': 'multipart/form-data'
            }
        }),
        postIncentiveNoImage: (request) => instance({
            'method': 'POST',
            'url': `/incentivesblobservice/PostIncentiveNoImage?&IbcNumber=${request.ibcNumber}&IncentiveId=${request.incentiveId}&Source=${request.source}`,
            'headers': {
                'content-type': 'text/plain'
            }
        }),
        postViewRebateRequest: (email) => instance({
          'method': 'POST',
          'url': `/myrebatereceipt?emailAddress=${email.email}&source=Web Portal`,
          'headers': {
            'content-type': 'text/plain'
          },
          transformResponse: [function (data) {
            const responseObj = unwrapResponse(data);
            if (!responseObj) return;
            return responseObj;
          }]
        })
    /*getAllProductBrands: () =>
        instance({
            'method': 'GET',
            'url': '/productbrand/getall',
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                return responseObj.Data;
            }],
        }),
    getAllIbcProducts: () =>
        instance({
            'method': 'GET',
            'url': '/ibcproduct/getall',
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                return responseObj.Data ? responseObj.Data : [{ Id: 0, SalesforceId: 0, Name: "null" }];
            }],
        }),
    getAllProductCategories: () =>
        instance({
            'method': 'GET',
            'url': '/productcategory/getall',
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                return responseObj.Data;
            }],
        }),

    searchProducts: (searchValue, pageNumber, pageSize) =>
        instance({
            'method': 'GET',
            'url': '/product/search',
            'params': {
                'searchValue': searchValue,
                'pageNumber': pageNumber,
                'pageSize': pageSize
            },
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                return responseObj.Data;
            }],
        }),
    updateProduct: (productDTO) =>
        instance({
            'method': 'PUT',
            'url': '/product',
            'data': {
                'ProductDTO': productDTO
            },
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
        }),
    createProduct: (productDTO) =>
        instance({
            'method': 'POST',
            'url': '/product/create',
            'data': {
                'ProductDTO': productDTO
            },
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                return responseObj.Data;
            }],
        }),
    deleteProduct: (productId) =>
        instance({
            'method': 'DELETE',
            'url': '/product',
            'params': {
                'productId': productId
            },
            'headers': {
                'content-type': 'application/json'
            },
        }),

    addImages: (formData) =>
        instance({
            method: 'post',
            url: '/productImage',
            data: formData,
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                return responseObj.Data[0];
            }],
        }),

    deleteProductImage: (imageId) =>
        instance({
            'method': 'DELETE',
            'url': '/productImage',
            'params': {
                'imageId': imageId
            },
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                return responseObj.Data[0];
            }],
        }),
    createOrder: (orderDTO) =>
        instance({
            'method': 'POST',
            'url': '/order/create',
            'data': {
                'OrderDTO': orderDTO
            },
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                return responseObj.Data;
            }],
        }),
    updateOrder: (orderDTO) =>
        instance({
            'method': 'PUT',
            'url': '/order/update',
            'data': {
                'OrderDTO': orderDTO
            },
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                return responseObj.Data;
            }],
        }),
    createMultipleOrderItems: (orderItems) =>
        instance({
            'method': 'POST',
            'url': '/orderitem/createmultiple',
            'data': {
                'orderItems': orderItems
            },
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                return responseObj.Data;
            }],
        }),
    createOrderItem: (orderItem) =>
        instance({
            'method': 'POST',
            'url': '/ordercart/create',
            'data': {
                'orderItem': orderItem
            },
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                return responseObj.Data;
            }],
        }),
    getLatestOrderId: () =>
        instance({
            'method': 'GET',
            'url': '/order/getlatestid',
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                return responseObj.Data;
            }],
        }),
    getAllCheckedOutOrders: () =>
        instance({
            'method': 'GET',
            'url': '/order/getallcheckedoutorders',
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                return responseObj.Data;
            }],
        }),
    getOrderByIdIncludeItems: (orderId) =>
        instance({
            'method': 'GET',
            'url': '/order/getbyidincludeitems/',
            'params': {
                'orderId': orderId,
            },
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                return responseObj.Data;
            }],
        }),*/
}

export default salesforceApi;