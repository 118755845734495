import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import AppLayout from '@crema/core/AppLayout';
import AuthRoutes from '@crema/utility/AuthRoutes';
import LocaleProvider from '@crema/utility/LocaleProvider';
import CremaThemeProvider from '@crema/utility/CremaThemeProvider';
import CremaStyleProvider from '@crema/utility/CremaStyleProvider';
import ContextProvider from '@crema/utility/ContextProvider';
import { ComposedContextProvider } from './globalState/composer';
import configureStore, { history } from './redux/store';
import CssBaseline from '@material-ui/core/CssBaseline';

import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { withStore } from 'react-context-hook';

const store = configureStore();

const initialClaimsState = { firstName: '', lastName: '', ibcNumber: '', emails: [], availableStores: [], notifications: [] };

const App = () => (
    <ContextProvider>
        <ComposedContextProvider>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <Provider store={store}>
                    <CremaThemeProvider>
                        <CremaStyleProvider>
                            <LocaleProvider>
                                <ConnectedRouter history={history}>
                                    <AuthRoutes>
                                        <CssBaseline />
                                        <AppLayout />
                                    </AuthRoutes>
                                </ConnectedRouter>
                            </LocaleProvider>
                        </CremaStyleProvider>
                    </CremaThemeProvider>
                </Provider>
            </MsalAuthenticationTemplate>
        </ComposedContextProvider>
    </ContextProvider>
);

export default withStore(App, initialClaimsState);
