import React from "react";
import { Box, Grid, makeStyles } from "@material-ui/core";
import Divider from '@mui/material/Divider';
import AppLogo from '../AppLogo';
import AppStoreLogos from '../AppStoreLogos';
import GooglePlayLogos from '../GooglePlayLogos';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useHistory } from 'react-router-dom';
import moment from "moment";

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: "1280px",
        margin: 'auto',
        padding: '46px 7.5px 26px',
        [theme.breakpoints.up('md')]: {
            padding: '40px 20px',
        },
    },
    category: {
        fontSize: 14,
        fontWeight: 500,
        padding: "0px 0px 5px",
        [theme.breakpoints.up('md')]: {
            fontSize: 16,
            padding: "0px 0px 4px",
        },
    },
    links: {
        fontSize: 11,
        padding: "3px 0px",
        [theme.breakpoints.up('md')]: {
            fontSize: 12,
            padding: "4px 0px",
        },
    },
    gridItem: {
        padding: '0px 7.5px 24px',
        [theme.breakpoints.up('md')]: {
            padding: '0px 20px 30px',
        },
    },
    copyright: {
        padding: "30px 0px 0px",
        [theme.breakpoints.up('md')]: {
            padding: "40px 0px 0px",
        },
    },
    dividedArea: {
        fontSize: 12,
        padding: "0px 7.5px 0px",
        [theme.breakpoints.up('md')]: {
            margin: "18px 0px 0px",
            padding: "0px 20px 0px",
        },
    },
    firstPanel: {
        fontSize: 28,
        fontWeight: 700,
    },
    socials: {
        fontSize: 14,
        padding: "30px 0px 0px",
    },
    socialIcons: {
        padding: '4px 0px 0px',
        margin: '0px 8px 0px 0px',
        [theme.breakpoints.up('md')]: {
            padding: '4px 0px 0px',
            margin: '0px 8px 0px 0px',
        },
    },
    footerLink: {
        cursor: 'pointer',
        "&:hover": {
            opacity: '80%'
        }
    }
}));

interface FooterLinkProps {
    text: string;
    url: string;
}

const FooterLink = ({ text, url }: FooterLinkProps) => {
    const classes = useStyles();

    const redirectToUrl = () => {
        window.open(url, '_blank');
    }

    return (
        <div className={classes.footerLink} onClick={redirectToUrl}>{text}</div>
    )
}

const IBCFooter = () => {
    const classes = useStyles();
    const history = useHistory();

    return (
        <Box className={classes.container}>
            <Grid container>
                <Grid item className={classes.gridItem} xs={12} md={3}>
                    <Grid container>
                        <Grid item>
                            <AppLogo />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box className={classes.socials}>Follow us</Box>
                        </Grid>
                        <Grid item className={`${classes.socialIcons} ${classes.footerLink}`}>
                            <FacebookIcon onClick={() => window.open("https://www.facebook.com/independentbuyerscompany/", '_blank')} sx={{ fontSize: 26.67 }} />
                        </Grid>
                        <Grid item className={`${classes.socialIcons} ${classes.footerLink}`}>
                            <LinkedInIcon onClick={() => window.open("https://www.linkedin.com/company/independentbuyerscoop/mycompany/", '_blank')} sx={{ fontSize: 26.67 }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={3} >
                    <Box className={classes.category}>Company</Box>
                    <Box className={classes.links}>
                        <FooterLink text="I.B.C. Website" url="https://www.ibcco-op.com/" />
                    </Box>
                    <Box className={classes.links}>
                        <FooterLink text="Our Leaders" url="https://www.ibcco-op.com/executiveteam" />
                    </Box>
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={3} >
                    <Box className={classes.category}>Get the Mobile App</Box>
                    <Box className={classes.links}>
                        <AppStoreLogos />
                    </Box>
                    <Box className={classes.links}>
                        <GooglePlayLogos />
                    </Box>
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={3} >
                    <Box className={classes.category}>Services</Box>
                    <Box className={classes.links}>
                        <FooterLink text="EasystorePro" url="https://www.easystorepro.com/" />
                    </Box>
                    <Box className={classes.links}>
                        <FooterLink text="EasystoreDash" url="https://www.easystoredash.com/" />
                    </Box>
                    <Box className={classes.links}>
                        <FooterLink text="EasystoreEats" url="https://www.easystoreeats.com/" />
                    </Box>
                    <Box className={classes.links}>
                        <FooterLink text="EasystorePrint" url="https://www.easystoreprint.com/" />
                    </Box>

                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={3} >
                    <Box className={classes.category}>Help Center</Box>
                    <Box className={classes.links} >
                        <div className={classes.footerLink} onClick={() => {
                            window.scrollTo(0, 0);
                            history.push({ pathname: '/account-details', state: { tabValue: 1 } });
                        }}>
                            Contact Your Regional Manager
                        </div>
                    </Box>
                </Grid>
            </Grid>
            <Grid item className={classes.dividedArea} xs={12} >
                <Divider />
                <Box className={classes.copyright}>© {moment().year()} Independent Buyer's Company</Box>
            </Grid>
        </Box>
    )
}

export default IBCFooter;