import React from 'react';

export const indexRedirectPage = [
    {
        auth: ['user'],
        routes: [
            {
                path: '/index',
                component: React.lazy(() => import('./Pages/IndexRedirectPage')),
            }
        ]
    }
];
