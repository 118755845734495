import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './shared/styles/index.css';
import App from './App';
import '@crema/services';
import reportWebVitals from './reportWebVitals';

import {MsalProvider} from "@azure/msal-react";


//import {InteractionType, PublicClientApplication} from "@azure/msal-browser";
//import {AuthenticationResult, EventType, PublicClientApplication} from "@azure/msal-browser";
import {PublicClientApplication} from "@azure/msal-browser";
import {msalConfig} from "authConfig";


const pca = new PublicClientApplication(msalConfig);

ReactDOM.render(<MsalProvider instance={pca}><App /></MsalProvider>, document.getElementById('root'));



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
