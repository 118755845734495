import React from 'react';

export const rebatePagesConfig = [
    {
        auth: ['user'],
        routes: [
            {
                path: '/rebate/invoice',
                component: React.lazy(() => import('./Pages/RebateInvoicePage')),
            }
        ]
    },
    {
        auth: ['user'],
        routes: [
            {
                path: '/rebate/fifth-check',
                component: React.lazy(() => import('./Pages/FifthCheckPage')),
            }
        ]
    }
];
