import React from 'react';
import { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import AppAnimate from '../../../@crema/core/AppAnimate';
import { makeStyles } from "@material-ui/core/styles";
import AppCard from '../../../@crema/core/AppCard';


const useStyles = makeStyles(theme => ({
    cardStyle: {
        margin: 'auto',
        display: 'block',
        transitionDuration: '0.3s',
        height: 'calc(100vh - 132px)',
        width: '90%',
    },
    vA: {
        background: `url(/assets/images/ads/SO22/Coke_Dreamworld_400x1000_SO22.jpg) no-repeat center center`,
        backgroundSize: 'cover'
    },
    vB: {
        background: `url(/assets/images/ads/SO22/Coke_Hydration_Mesh_400x1000_SO22.jpg) no-repeat center center`,
        backgroundSize: 'cover'
    },
    vC: {
        background: `url(/assets/images/ads/SO22/Cold_Vault_Resets_400x1000_SO22.jpg) no-repeat center center`,
        backgroundSize: 'cover'
    },
    vD: {
        background: `url(/assets/images/ads/SO22/easystorePRO_400x1000_SO22.jpg) no-repeat center center`,
        backgroundSize: 'cover'
    },
    hA: {
        background: `url(/assets/images/ads/SO22/Coke_Dreamworld_1008x192_SO22.jpg) no-repeat center center`,
        backgroundSize: 'cover'
    },
    hB: {
        background: `url(/assets/images/ads/SO22/Coke_Hydration_Mesh_1008x192_SO22.jpg) no-repeat center center`,
        backgroundSize: 'cover'
    },
    hC: {
        background: `url(/assets/images/ads/SO22/Cold_Vault_Reset_1008x192_SO22.jpg) no-repeat center center`,
        backgroundSize: 'cover'
    },
    hD: {
        background: `url(/assets/images/ads/SO22/easystorePRO_1008x192_SO22.jpg) no-repeat center center`,
        backgroundSize: 'cover'
    },
}));

const AdPanel = () => {
    const classes = useStyles();
    const bgs = [ classes.vC, classes.vD];
    //const hBackgrounds = [classes.hA, classes.hB, classes.hB];

    const bgCount = bgs.length;
    const [bgIndex, setBgIndex] = useState(0);
    const backgroundTimeInterval = 8000; // milliseconds

    useEffect(() => {
        const interval = setInterval(() => {
            setBgIndex(bgIndex => bgIndex + 1); 
        }, backgroundTimeInterval);

        return () => { clearInterval(interval) };
    }, []);

    return (
        <AppAnimate animation='transition.slideUpIn' delay={100}>
            <Box>
                <AppCard className={`${classes.cardStyle} ${bgs[bgIndex % bgCount]}`}>
                    
                </AppCard>
            </Box>
        </AppAnimate>
    );
};

export default AdPanel;
