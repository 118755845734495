export const b2cPolicies = {
  names: {
    signIn: "B2C_1_MemberPortal"
  },
  authorities: {
    signIn: {
      authority: "https://ibcmemportal.b2clogin.com/ibcmemportal.onmicrosoft.com/b2c_1_memberportal"
    }
  },
  authorityDomain: "ibcmemportal.b2clogin.com"
}


export const msalConfig = {
  auth: {
    clientId: "f11180e7-2216-48c7-aed6-6ec467233e32",
    authority: b2cPolicies.authorities.signIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: process.env.AZUREB2C_REDIRECT_URI,
    navigateToLoginRequestUri: false,
    postLogoutRedirectUri: process.env.AZUREB2C_POST_LOGOUT_REDIRECT_URI
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false
  }
};

export const loginRequest = {
  scopes: ["User.Read"]
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com"
}