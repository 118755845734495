import React from 'react';

export const detailsPagesConfig = [
    {
        auth: ['user'],
        routes: [
            {
                path: '/account-details',
                component: React.lazy(() => import('./Pages/AccountDetailsPage')),
            }
        ],
    }
];
