import React from 'react';

export const servicesPagesConfig = [
    {
        auth: ['user'],
        routes: [
            {
                path: '/services/felonious-insurance',
                component: React.lazy(() => import('./Pages/ServicesFeloniousInsurancePage')),
            }
        ]
    }
];
