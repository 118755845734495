import React from 'react';

export const programsPagesConfig = [
    {
        auth: ['user'],
        routes: [
            {
                path: '/programs/active',
                component: React.lazy(() => import('./Pages/ProgramsActivePage')),
            },
            {
                path: '/programs/available',
                component: React.lazy(() => import('./Pages/ProgramsAvailablePage')),
            }
        ]
    }
];
