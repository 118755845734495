import React from 'react';
import { Link } from "@mui/material";
import { useStore } from 'react-context-hook';
import { IPublicClientApplication } from "@azure/msal-browser";

function handleLogout(instance: { logoutRedirect: () => Promise<any>; }) {
    instance.logoutRedirect().catch((e: any) => { console.error(e) });
}

export const LogOutLink = () => {

    const [ibcNumber] = useStore('ibcNumber');
    const [firstName] = useStore('firstName');
    const [lastName] = useStore('lastName');
    const [emailClaim] = useStore('emailClaim');
    const [msalInstance] = useStore<IPublicClientApplication>('msalInstance');

    //alert(JSON.stringify(currentAccount));
    if (process.env.NODE_ENV === 'test') console.log('ibcNumber: ' + ibcNumber + 'firstName: ' + firstName + 'lastName: ' + lastName + 'email: ' + emailClaim);
    return (
        <Link underline='none' onClick={() => handleLogout(msalInstance)}> Logout (Store: {ibcNumber}) </Link>
    );
}

