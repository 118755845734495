import React from 'react';

export const chaintoolsPagesConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/chaintools',
        component: React.lazy(() => import('./Pages/ChainToolsPage')),
      },
    ],
  }
];